import request from "../utils/request";

/* 角色授权菜单查询 */
export const sysRoleMenus = (params) => {
    return request.get('/back/t/role/all/menus', params)
}

/* 角色删除 */
export const sysRoleDelete = (id) => {
    return request.post(`/back/t/role/deleteById/${id}`)
}

/* 角色无效 */
export const sysRoleDisable = (id, enable, disableReason) => {
    return request.post(`/back/t/role/disableById/${id}/${enable}?disableReason=${disableReason}`,)
}

/* 角色新增 */
export const sysRoleAdd = (params) => {
    return request.post('/back/t/role/insert', params)
}

/* 角色分页查询 */
export const sysRoleList = (params) => {
    return request.get('/back/t/role/list', params)
}

/* 有效角色列表查询 */
export const sysRoleListNoPage = () => {
    return request.get('/back/t/role/listRoles')
}

/* 角色更新 */
export const sysRoleUpdate = (params) => {
    return request.post('/back/t/role/update', params)
}

/* 修改角色对应菜单 */
export const sysRoleMenuUpdate = (params) => {
    return request.post('/back/t/role/update/role/menu', params)
}