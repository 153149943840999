<template>
  <div class="sys-user-manage">
    <div class="search-area">
      <el-form size="small" :inline="true" :model="searchParams" class="">
        <el-form-item label="姓名/手机号：">
          <el-input
            v-model="searchParams.searchContent"
            placeholder="请输入账号/姓名/手机号"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button v-show="authorities.includes('BACK:BACK_T_USER_PAGE_LIST')" type="primary" @click="handleSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="opt-area">
      <el-button
        v-show="authorities.includes('BACK:BACK_T_USER_CREATE')"
        size="small"
        @click="handleCreateUser"
        type="primary"
        icon="el-icon-plus"
        >新增</el-button
      >
      <el-button 
        v-show="authorities.includes('BACK:BACK_T_USER_UPDATE')"
        :disabled="selectUserIds.length != 1"
        size="small"
        @click="handleEditUserInfo('batch')"
        type="primary"
        icon="el-icon-edit"
        >编辑</el-button
      >
      <el-button  
        v-show="authorities.includes('BACK:BACK_T_USER_ENABLE_UPDATE')"
        :disabled="selectUserIds.length == 0"
        size="small"
        @click="handleEnbleOrDisableUser('batch', 1)"
        type="primary"
        icon="el-icon-video-play"
        >启用</el-button
      >
      <el-button
        v-show="authorities.includes('BACK:BACK_T_USER_ENABLE_UPDATE')"
        :disabled="selectUserIds.length == 0"
        size="small"
        @click="handleEnbleOrDisableUser('batch', 0)"
        type="primary"
        icon="el-icon-video-pause"
        >禁用</el-button
      >
      <el-button 
        :disabled="selectUserIds.length == 0"
        size="small"
        @click="handleDeleteUser('batch')"
        type="primary"
        icon="el-icon-delete"
        >删除</el-button
      >
      <el-button  
        v-show="authorities.includes('BACK:BACK_T_USER_PASS_INIT')"
        :disabled="selectUserIds.length == 0"
        size="small"
        @click="handleResetPassword('batch')"
        type="primary"
        icon="el-icon-key"
        >重置密码</el-button
      >
    </div>
    <div class="list-area">
      <el-table
        v-show="authorities.includes('BACK:BACK_T_USER_PAGE_LIST')"
        height="100%"
        :data="userList"
        v-loading="loading"
        row-key="id"
        style="width: 100%; min-width: 1200px"
        @selection-change="handleSelectionChange"
        :header-cell-style="{ background: '#eef1f6' }"
      >
        <el-table-column align="center" type="selection" width="55"> </el-table-column>
        <el-table-column prop="id" align="center" label="ID" width="180">
        </el-table-column>
        <el-table-column prop="username" align="center" label="账号">
        </el-table-column>
        <el-table-column prop="realName" align="center" label="姓名">
        </el-table-column>
        <el-table-column prop="mobile" align="center" label="手机号">
        </el-table-column>
        <el-table-column prop="enable" align="center" label="状态" width="80">
          <template slot-scope="scope">
            <span>{{ scope.row.state == 1 ? "有效" : "无效" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTimeString"
          align="center"
          label="创建时间"
        >
        </el-table-column>
        <el-table-column prop="address" align="center" label="操作">
          <template slot-scope="scope">
            <el-link
              v-show="authorities.includes('BACK:BACK_T_USER_UPDATE')"
              type="primary"
              :underline="false"
              @click="handleEditUserInfo('row', scope.row)"
              >编辑</el-link
            >
            <el-link
              v-show="authorities.includes('BACK:BACK_T_USER_PASS_INIT')"
              type="primary"
              class="m-l-10"
              :underline="false"
              @click="handleResetPassword('row', scope.row)"
              >重置密码</el-link
            >
            <el-link
              type="primary"
              class="m-l-10"
              v-show="scope.row.state == 1 && authorities.includes('BACK:BACK_T_USER_ENABLE_UPDATE')"
              :underline="false"
              @click="handleEnbleOrDisableUser('row', 0 ,scope.row)"
              >禁用</el-link
            >
            <el-link
              type="primary"
              class="m-l-10"
              v-show="scope.row.state == 0 && authorities.includes('BACK:BACK_T_USER_ENABLE_UPDATE')"
              :underline="false"
              @click="handleEnbleOrDisableUser('row', 1,scope.row)"
              >启用</el-link
            >
            <el-link
              type="primary"
              class="m-l-10"
              :underline="false"
              @click="handleDeleteUser('row', scope.row)"
              >删除</el-link
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="bottom-area">
      <el-pagination
        v-show="authorities.includes('BACK:BACK_T_USER_PAGE_LIST')"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="searchParams.pageNum"
        :page-size="searchParams.pageSize"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="dialogAddUserTitle"
      :visible.sync="dialogAddUser"
      width="700px"
    >
      <div>
        <el-form
          ref="saveUserData"
          size="small"
          :model="saveUserData"
          :rules="rules"
          label-width="100px"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="用户头像" prop="headImg">
                <div class="img-select" @click="handleImgSelectClick">
                  <img
                    v-if="saveUserData.headImg"
                    class="add-head"
                    :src="saveUserData.headImg"
                    alt=""
                  />
                  <span v-else class="el-icon-plus add-icon"></span>
                </div>
                <input
                  type="file"
                  @change="handleImgSelectChange"
                  accept="image/*"
                  ref="imgSelect"
                  style="display: none"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="用户名" prop="username">
                <el-input
                  :disabled="dialogAddUserTitle == '编辑用户'"
                  v-model="saveUserData.username"
                  placeholder="请输入用户名"
                  maxlength="20"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="password">
                <el-input
                  :disabled="dialogAddUserTitle == '编辑用户'"
                  v-model="saveUserData.password"
                  placeholder="请输入密码"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="真实姓名" prop="realName">
                <el-input
                  v-model="saveUserData.realName"
                  placeholder="请输入真实姓名"
                  maxlength="20"
                  show-word-limit
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="手机号" prop="mobile">
                <el-input
                  v-model="saveUserData.mobile"
                  placeholder="请输入手机号"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="用户角色" prop="roleIds">
                <el-select
                  class="user_role_select"
                  v-model="saveUserData.roleIds"
                  multiple
                  placeholder="请选择角色"
                >
                  <el-option
                    v-for="item in roleList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="状态" prop="state">
                <el-radio-group v-model="saveUserData.state">
                  <el-radio :label="1">有效</el-radio>
                  <el-radio :label="0">无效</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogAddUser = false">取 消</el-button>
        <el-button size="small" type="primary" @click="handleConfirmAddUser"
          >保 存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  createSysUser,
  sysUserList,
  sysUserPasswordInit,
  sysUserDel,
  sysUserDetail,
  sysUserEnable,
  sysUserUpdate,
} from "@/api/sysUser";
import { sysRoleListNoPage } from "@/api/sysRole";
import { ossSimpleFileUpload } from "@/api/common";
import { mapState } from 'vuex';
export default {
  data() {
    return {
      selectUserIds: [],
      userList: [],
      searchParams: {
        pageNum: 1,
        pageSize: 10,
        searchContent: "",
      },
      dialogAddUserTitle: "",
      dialogAddUser: false,
      editUserId: 0,
      total: 0,
      loading: false,
      saveUserData: {
        id: null,
        username: "",
        password: "",
        headImg: "",
        realName: "",
        mobile: "",
        state: 1,
        roleIds: [],
      },
      roleList: [],
      rules: {
        username: [
          { required: true, message: "用户名不能为空", trigger: "change" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "change" },
        ],
        headImg: [
          { required: true, message: "头像不能为空", trigger: "change" },
        ],
        realName: [
          { required: true, message: "真实姓名不能为空", trigger: "change" },
        ],
        mobile: [
          { required: true, message: "手机号不能为空", trigger: "change" },
          { validator: (rule, value, callback) => {
            if(!/^1\d{10}$/.test(value)){
              return callback(new Error('手机号码格式不正确！'))
            }
            return callback()
          }}
        ],
        roleIds: [
          { required: true, message: "用户角色不能为空", trigger: "change" },
        ],
        state: [{ required: true, message: "状态不能为空", trigger: "change" }],
      },
    };
  },
  components: {},
  computed: {
    ...mapState(['authorities']),
  },
  created() {
    this.getSysUserList();
  },
  mounted() {},
  methods: {
    /* 列表选择 */
    handleSelectionChange(list){
      console.log(list)
      this.selectUserIds = list.map( (item) => {
        return item.id
      })
    },
    /* 修改用户信息 */
    async handleEditUserInfo(type, row) {
      let userId 
      if(type == 'batch'){
        userId = this.selectUserIds[0]
      }else if(type == 'row'){
        userId = row.id
      }
      this.getRoleList()
      const { username, realName, mobile, id, headImg, state, userRoleShowVO: {haveRoleIds}} = await sysUserDetail(userId)
      this.saveUserData = {
        username,
        password: '******************',
        headImg,
        realName,
        mobile,
        state,
        roleIds: haveRoleIds,
        id,
      }
      this.dialogAddUserTitle = "编辑用户";
      this.dialogAddUser = true;
    },
    /* 删除用户 */
    handleDeleteUser(type, row) {
      let userIds = []
      if(type == 'batch'){
        userIds = this.selectUserIds
      }else if(type == 'row'){
        userIds = [row.id]
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          await sysUserDel({
            userIds,
          });
          this.msgSuccess("删除成功！");
          this.getSysUserList()
        } catch (err) {
          let msg = err.msg ? err.msg : "删除用户发生异常！";
          console.error("sysUserDel: ", err);
          this.msgError(msg);
        }
      });
    },
    /* 重置用户密码 */
    handleResetPassword(type, row) {
      let userIds = []
      if(type == 'batch'){
        userIds = this.selectUserIds
      }else if(type == 'row'){
        userIds = [row.id]
      }
      this.$confirm("确定初始化该用户密码吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          await sysUserPasswordInit({
            userIds,
          });
          this.msgSuccess("密码重置成功！");
        } catch (err) {
          let msg = err.msg ? err.msg : "重置密码发生异常！";
          console.error("sysUserPasswordInit: ", err);
          this.msgError(msg);
        }
      });
    },
    /* 启用或者禁用用户 */
    async handleEnbleOrDisableUser(type, enable, row) {
      let userIds = []
      if(type == 'batch'){
        userIds = this.selectUserIds
      }else if(type == 'row'){
        userIds = [row.id]
      }
      this.$confirm(`确定要${enable == 1 ? '启用' : '禁用'}吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          await sysUserEnable({
            enable,
            userIds,
          });
          this.getSysUserList()
          this.msgSuccess("操作成功！");
        } catch (err) {`${enable == 1 ? '启用' : '禁用'}发生异常！`;
          console.error("sysUserEnable: ", err);
          this.msgError(msg);
        }
      });
    },
    /* 处理搜索 */
    handleSearch() {
      this.searchParams.pageNum = 1;
      this.getSysUserList();
    },
    /* 获取角色列表 */
    async getRoleList() {
      try {
        this.roleList = await sysRoleListNoPage();
      } catch (err) {
        let msg = err.msg ? err.msg : "获取角色列表发生异常！";
        console.error("getRoleList: ", err);
        this.msgError(msg);
      }
    },
    /* 新增用户按钮点击 */
    handleCreateUser() {
      this.dialogAddUserTitle = "新增用户";
      this.dialogAddUser = true;
      this.getRoleList();
      this.$nextTick(() => {
        if(this.$refs["saveUserData"]){
          this.$refs["saveUserData"].resetFields();
        }
      });
    },
    /* 点击选择头像 */
    handleImgSelectClick() {
      this.$refs["imgSelect"].value = "";
      this.$refs["imgSelect"].click();
    },
    /* 处理选择了头像 */
    async handleImgSelectChange() {
      const file = this.$refs["imgSelect"].files[0];
      try {
        const { fileUrl } = await ossSimpleFileUpload(file);
        this.saveUserData.headImg = fileUrl;
      } catch (err) {
        let msg = err.msg ? err.msg : "图片上传发生异常！";
        console.error("ossSimpleFileUpload: ", err);
        this.msgError(msg);
      }
    },
    /* 页面变化 */
    handleCurrentChange(pageNum) {
      this.searchParams.pageNum = pageNum;
      this.getSysUserList()
    },
    /* 页面size变化 */
    handleSizeChange(pageSize) {
      this.searchParams.pageSize = pageSize;
      this.getSysUserList()
    },
    /* 确定添加用户 */
    handleConfirmAddUser() {
      this.$refs["saveUserData"].validate(async (valid) => {
        if (valid) {
          let optName = "";
          if(this._addOrEditFlag){
            return
          }
          this._addOrEditFlag = true
          try {
            if (this.dialogAddUserTitle == "新增用户") {
              optName = "新增";
              await createSysUser(this.saveUserData);
            } else if (this.dialogAddUserTitle == "编辑用户") {
              optName = "编辑";
              await sysUserUpdate({
                ...this.saveUserData,
                password: null,
              });
            }
            this._addOrEditFlag = false
            this.getSysUserList();
            this.dialogAddUser = false;
            this.msgSuccess(`${optName}成功`);
          } catch (err) {
            this._addOrEditFlag = false
            let msg = err.msg ? err.msg : `${optName}用户发生异常！`;
            console.error("handleConfirmAddUser err: ", err);
            this.msgError(msg);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /* 获取用户列表 */
    async getSysUserList() {
      if(!this.authorities.includes('BACK:BACK_T_USER_PAGE_LIST')){
        return
      }
      try {
        this.loading = true
        const { total, records, pages, current } = await sysUserList(this.searchParams);
        this.loading = false
        this.total = total;
        this.userList = records;
        if(current > pages && pages) {
          this.searchParams.pageNum = pages 
          this.getSysUserList()
        }
      } catch (err) {
        this.loading = false
        let msg = err.msg ? err.msg : "获取用户列表发生异常";
        console.error("getSysUserList err: ", err);
        this.msgError(msg);
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.sys-user-manage {
  height: 100%;
  padding: 20px 20px 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .search-area {
    font-size: 12px;
    padding: 0 10px 0;
    text-align: left;
  }
  .opt-area {
    padding: 0 10px 10px;
    text-align: right;
  }
  .list-area {
    flex: 1;
    overflow-x: auto;
    .m-l-10 {
      margin-left: 10px;
    }
  }
  .bottom-area {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .img-select {
    height: 84px;
    width: 84px;
    border: 2px solid #ebebeb;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .add-head {
      width: 84px;
      height: 84px;
    }
    .add-icon {
      font-size: 30px;
    }
  }
  .user_role_select{
    width: 100%;
  }
}
</style>